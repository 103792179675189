.rubik-moonrocks-regular {
  font-family: "Rubik Moonrocks", sans-serif;
  font-weight: 400;
  font-style: normal;
}

html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-origin: 50%;
  font-size: 50px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Rubik Moonrocks", sans-serif;
  font-weight: 400;
  font-style: normal;
  h5 {
    margin: 0px;
    padding: 0px;
  }
  p {
    margin: 0px;
    padding: 0px;
    font-size: 7vw;
    span {
      display: inline-block;
      margin-right: 3vw;
    }
  }
}
