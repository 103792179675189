.rubik-moonrocks-regular {
  font-family: Rubik Moonrocks, sans-serif;
  font-style: normal;
  font-weight: 400;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: 50%;
  color: red;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Rubik Moonrocks, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

body h5 {
  margin: 0;
  padding: 0;
}

body p {
  margin: 0;
  padding: 0;
  font-size: 7vw;
}

body p span {
  margin-right: 3vw;
  display: inline-block;
}
/*# sourceMappingURL=index.c0b3c5cf.css.map */
